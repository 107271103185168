import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'
import moment from "moment"

const data = [
    {
        id: 1,
        name: "administrator",
        email: "admin@example.org",
        email_verified_at: "2022-05-04T05:41:09.000000Z",
        created_at: "2022-05-04T05:41:09.000000Z",
        updated_at: "2022-05-04T05:41:09.000000Z"
    },
    {
        id: 2,
        name: "Mr. Geo Batz",
        email: "carol.okeefe@example.com",
        email_verified_at: "2022-05-04T05:41:09.000000Z",
        created_at: "2022-05-04T05:41:11.000000Z",
        updated_at: "2022-05-04T05:41:11.000000Z"
    },
    {
        id: 3,
        name: "Monserrat Wolf",
        email: "hester.gleichner@example.com",
        email_verified_at: "2022-05-04T05:41:09.000000Z",
        created_at: "2022-05-04T05:41:11.000000Z",
        updated_at: "2022-05-04T05:41:11.000000Z"
    },
    {
        id: 4,
        name: "Ansley Sipes",
        email: "bartoletti.alia@example.net",
        email_verified_at: "2022-05-04T05:41:09.000000Z",
        created_at: "2022-05-04T05:41:11.000000Z",
        updated_at: "2022-05-04T05:41:11.000000Z"
    },
    {
        id: 5,
        name: "Prof. Gilberto Schneider",
        email: "gjacobi@example.org",
        email_verified_at: "2022-05-04T05:41:09.000000Z",
        created_at: "2022-05-04T05:41:11.000000Z",
        updated_at: "2022-05-04T05:41:11.000000Z"
    },
    {
        id: 6,
        name: "Trisha Waelchi DDS",
        email: "alia48@example.net",
        email_verified_at: "2022-05-04T05:41:09.000000Z",
        created_at: "2022-05-04T05:41:11.000000Z",
        updated_at: "2022-05-04T05:41:11.000000Z"
    },
    {
        id: 7,
        name: "Rhea Veum PhD",
        email: "littel.jace@example.com",
        email_verified_at: "2022-05-04T05:41:10.000000Z",
        created_at: "2022-05-04T05:41:11.000000Z",
        updated_at: "2022-05-04T05:41:11.000000Z"
    },
    {
        id: 8,
        name: "Angus Hane",
        email: "geoffrey.yundt@example.com",
        email_verified_at: "2022-05-04T05:41:10.000000Z",
        created_at: "2022-05-04T05:41:11.000000Z",
        updated_at: "2022-05-04T05:41:11.000000Z"
    },
    {
        id: 9,
        name: "Marietta Dickens",
        email: "irving.wilkinson@example.net",
        email_verified_at: "2022-05-04T05:41:10.000000Z",
        created_at: "2022-05-04T05:41:11.000000Z",
        updated_at: "2022-05-04T05:41:11.000000Z"
    },
    {
        id: 10,
        name: "Casey Carroll",
        email: "zechariah40@example.org",
        email_verified_at: "2022-05-04T05:41:10.000000Z",
        created_at: "2022-05-04T05:41:11.000000Z",
        updated_at: "2022-05-04T05:41:11.000000Z"
    },
    {
        id: 11,
        name: "Deanna Larkin",
        email: "keely.lang@example.com",
        email_verified_at: "2022-05-04T05:41:11.000000Z",
        created_at: "2022-05-04T05:41:11.000000Z",
        updated_at: "2022-05-04T05:41:11.000000Z"
    },
    {
        id: 12,
        name: "Test1",
        email: "test1@example.org",
        email_verified_at: "2022-05-04T05:52:20.000000Z",
        created_at: "2022-05-04T05:51:56.000000Z",
        updated_at: "2022-05-04T05:52:20.000000Z"
    },
    {
        id: 13,
        name: "Test2",
        email: "test2@example.org",
        email_verified_at: "2022-05-04T06:08:24.000000Z",
        created_at: "2022-05-04T06:08:15.000000Z",
        updated_at: "2022-05-04T06:08:24.000000Z"
    },
    {
        id: 14,
        name: "Test3",
        email: "test3@example.org",
        email_verified_at: "2022-05-04T06:09:11.000000Z",
        created_at: "2022-05-04T06:08:49.000000Z",
        updated_at: "2022-05-04T06:09:11.000000Z"
    },
    {
        id: 15,
        name: "test4",
        email: "test4@example.org",
        email_verified_at: "2022-05-04T06:29:39.000000Z",
        created_at: "2022-05-04T06:23:47.000000Z",
        updated_at: "2022-05-04T06:29:39.000000Z"
    },
    {
        id: 16,
        name: "Test5",
        email: "test5@example.org",
        email_verified_at: "2022-05-04T06:33:05.000000Z",
        created_at: "2022-05-04T06:30:54.000000Z",
        updated_at: "2022-05-04T06:33:05.000000Z"
    },
    {
        id: 17,
        name: "Test6",
        email: "test6@example.org",
        email_verified_at: "2022-05-04T06:35:00.000000Z",
        created_at: "2022-05-04T06:34:50.000000Z",
        updated_at: "2022-05-04T06:35:00.000000Z"
    },
    {
        id: 18,
        name: "Test7",
        email: "test7@example.org",
        email_verified_at: "2022-05-04T07:04:32.000000Z",
        created_at: "2022-05-04T07:02:34.000000Z",
        updated_at: "2022-05-04T07:04:32.000000Z"
    }
]

mock.onPost('/api/v1/users-mock').reply(request => {
    const user = JSON.parse(request.data)
    user.created_at = moment().format('YYYY-MM-DD HH:mm:ss')
    data.push(user)
    return [200, user]
})

mock.onGet('/api/v1/users-mock').reply(config => {
    const {params = {name: '', email: '', page: 1}} = config
    const perPage = 10

    const filteredData = data.filter(
        item => {
            if (params.name && !item.name.toLowerCase().includes(params.name.toLowerCase())) return false
            if (params.email && !item.email.toLowerCase().includes(params.email.toLowerCase())) return false
            return true
        }
    )

    return new Promise(function (resolve, reject) {
        setTimeout(function () {
            resolve([
                200,
                {
                    data: filteredData,
                    meta: {
                        paginated: paginateArray(filteredData, perPage, params.page),
                        current_page: params.page,
                        per_page: 10,
                        total: filteredData.length
                    }
                }

            ])
        }, 1000)
    })
})
