import { useState } from "react"
import TranslateDocumentContext from "./TranslateDocumentContext"

export function TranslateDocumentContextProvider({ children }) {
    const [document, setDocument] = useState(JSON.parse(localStorage.getItem('documentDataByID')) ? JSON.parse(localStorage.getItem('documentDataByID')) : null)


    return <TranslateDocumentContext.Provider value={{ document, setDocument }}
    >
        {children}
    </TranslateDocumentContext.Provider>
}