import Axios from "axios"
import { configure } from 'axios-hooks'
import LRU from 'lru-cache'
import i18n from "i18next"

const axios = Axios.create({
    baseURL: `${window.location.protocol}//${window.location.hostname}:${process.env.REACT_APP_BACKEND_PORT}`,
    // baseURL: 'https://tutraduces.test:4443',
    withCredentials: true
})

const cache = new LRU({ max: 10 })

axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    config.headers = {
        Accept: 'application/json',
        locale: i18n.language
        // Authorization: `Bearer ${localStorage.getItem('token')}`
    }
    return config
}, function (error) {
    // Do something with request error
    return Promise.reject(error)
})

export const setupInterceptors = (history, setUserData) => {
    axios.interceptors.response.use(res => {
        // success
        return res
    }, error => {
        // const { status } = err.response
        if (error) {
            if (error.response) {
                if (error.response.status === 409) {
                    history.push('/resend-email')
                }
            }
        }

        return Promise.reject(error)
    })
}

const defaultOptions = {
    useCache: false
}

configure({ axios, cache, defaultOptions })

export default axios
