import { useContext, useEffect, useState } from "react"
import useAxios from "axios-hooks"
import { AbilityContext } from '@src/utility/context/Can'
import UserContext from "../contexts/UserContext"

const TENANT_NOT_FOUND_ERROR = 'Tenant not found'

const AuthenticatedUser = ({ children }) => {

    const [isLoggedIn, setIsLoggedIn] = useState(null)
    const [userData, setUserData] = useState(null)
    const ability = useContext(AbilityContext)

    const [{ response, error }] = useAxios('/api/v1/user')

    useEffect(() => {
        if (response && response.status === 200) {
            const data = { ...response.data, role: 'admin', ability: [{ action: 'manage', subject: 'all' }], isLoggedIn: true }
            ability.update(data.ability)
            setUserData(data)
            setIsLoggedIn(true)
        } else {
            setUserData({ isLoggedIn: false })
        }
    }, [response])

    useEffect(() => {
        if (error && error.response.status === 401) {
            setIsLoggedIn(false)
        } else if (error && error?.response?.data?.data === TENANT_NOT_FOUND_ERROR) {
            window.location.assign(`${window.location.protocol}//${process.env.REACT_APP_HOST}`)
        }
    }, [error])

    return isLoggedIn === null ? null : <UserContext.Provider value={{ userData, setUserData }}>
        {children}
    </UserContext.Provider>
}

export { AuthenticatedUser }